import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, Input, inject, model } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { LabeledValueInlineContainerComponent } from '@shared-lib/components/labeled-value-inline-container/labeled-value-inline-container.component';
import { NgRepeatDirective } from '@shared-lib/directives/ngRepeat.directive';
import { ENUM_MortgagePartStepTypeMap } from '@shared-lib/maps';
import { MonthsToTextPipe } from '@shared-lib/pipes/months-to-text.pipe';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { MortgagePartFormValues } from '../../../mortgage-borrowed-amount-and-term-form/utils/form-utils';
import { MortgageFormProductMatchingInfo } from '../../models/mortgage-form-product-matching-info.model';
import { SKIPPED_PRODUCT_MATCH_PLACEHOLDER } from '../../mortgage-product-match-form.component';
import { ProductMatchItemComponent } from '../product-match-item/product-match-item.component';
import { ProductMatchItemListStore } from './product-match-item-list.store';

@Component({
  selector: 'cui-product-match-item-list',
  imports: [
    ProductMatchItemComponent,
    AsyncPipe,
    NgRepeatDirective,
    SkeletonModule,
    MonthsToTextPipe,
    DatePipe,
    LabeledValueInlineContainerComponent,
    TooltipModule,
  ],
  templateUrl: './product-match-item-list.component.html',
  styleUrl: './product-match-item-list.component.scss',
  providers: [provideComponentStore(ProductMatchItemListStore)],
})
export class ProductMatchItemListComponent {
  @Input({ required: true }) set mortgagePartInfo({
    productMatchingInfo,
    mortgagePart,
  }: {
    productMatchingInfo: MortgageFormProductMatchingInfo;
    mortgagePart: MortgagePartFormValues;
  }) {
    this.componentStore.getProductsForMatching({ productMatchingInfo, mortgagePart });
  }

  selectedProductId = model<string>();

  private readonly componentStore = inject(ProductMatchItemListStore);

  protected readonly products$ = this.componentStore.products$;
  protected readonly mortgagePartInfo$ = this.componentStore.mortgagePartInfo$;
  protected readonly isLoadingProducts$ = this.componentStore.isLoadingProducts$;
  protected readonly isLoadingMoreProducts$ = this.componentStore.isLoadingMoreProducts$;
  protected readonly isLastPage$ = this.componentStore.isLastPage$;

  protected readonly ENUM_MortgagePartStepTypeMap = ENUM_MortgagePartStepTypeMap;
  protected readonly SKIPPED_PRODUCT_MATCH_PLACEHOLDER = SKIPPED_PRODUCT_MATCH_PLACEHOLDER;

  loadMoreProducts(): void {
    this.componentStore.loadMoreProducts();
  }
}
