import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IconComponent } from '@shared-lib/components/icon/icon.component';
import { ButtonDirective } from 'primeng/button';
import { CarouselIndicatorsComponent } from './components/carousel-indicators/carousel-indicators.component';

@Component({
  selector: 'cui-carousel',
  imports: [CarouselIndicatorsComponent, IconComponent, ButtonDirective],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
})
export class CarouselComponent implements AfterViewInit {
  @ViewChild('carouselItemsContainer') carouselItemsContainer!: ElementRef;

  @Input() variant: 'light' | 'dark' = 'light';
  @Input() arrowButtonStyleClass = 'p-button-outlined';
  @Input() disableNavigation = false;
  @Input() hideIndicators = false;
  @Input({ required: true }) itemsLength = 0;
  @Input() set setCurrentPage(num: number) {
    this.currentPage = num || 0;
    this.move();
  }

  @Output() pageChange = new EventEmitter<number>();
  currentPage = 0;

  ngAfterViewInit(): void {
    this.move();
  }

  swipeLeft(): void {
    if (this.disableNavigation) return;
    if (this.currentPage == this.itemsLength - 1) return;
    this.currentPage += 1;
    this.move();
    this.pageChange.emit(this.currentPage);
  }

  swipeRight(): void {
    if (this.disableNavigation) return;
    if (this.currentPage == 0) return;
    this.currentPage += -1;
    this.move();
    this.pageChange.emit(this.currentPage);
  }

  move(): void {
    if (!this.carouselItemsContainer?.nativeElement) return;
    this.carouselItemsContainer.nativeElement.style.transform = `translateX(${100 * -this.currentPage}%)`;
  }
}
