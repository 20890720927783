import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'term',
  standalone: true,
})
export class TermPipe implements PipeTransform {
  transform(termInMonths: number | null): string {
    if (termInMonths == null) return '-';

    const years = Math.floor(termInMonths / 12);
    const months = termInMonths % 12;
    if (months) {
      if (years) {
        return `${years}y ${months}m`;
      }
      return `${months}m`;
    }
    return `${years}y`;
  }
}
