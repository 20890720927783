import { DatePipe, NgClass, PercentPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { HTTP_CP_MatchMortgageProducts_Response_MortgageProduct } from '@api-new/mortgageservice';
import { ENUM_MortgagePartStepTypeMap } from '@shared-lib/maps';
import { TermPipe } from '@shared-lib/pipes/term.pipe';
import { getColorByMatchingScore } from '@shared-lib/utils';

@Component({
  selector: 'cui-product-match-item',
  imports: [DatePipe, PercentPipe, TermPipe, NgClass],
  templateUrl: './product-match-item.component.html',
  styleUrl: './product-match-item.component.scss',
})
export class ProductMatchItemComponent {
  @Input({ required: true }) product!: HTTP_CP_MatchMortgageProducts_Response_MortgageProduct;
  @Input({ required: true }) selected!: boolean;

  protected readonly ENUM_MortgagePartStepTypeMap = ENUM_MortgagePartStepTypeMap;
  protected readonly getColorByMatchingScore = getColorByMatchingScore;
}
