<div class="otp-container text-center">
  @for (digit of result; track $index) {
    <input
      #optInput
      type="number"
      (paste)="onPaste($event)"
      (keyup)="onKeyUp($event, $index)"
      [(ngModel)]="result[$index]" />
  }
</div>
