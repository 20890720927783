<div
  class="product-wrapper bg-gray-5 border-radius-3 px-4 py-3 text-align grid gap-3"
  [class.selected]="selected">
  <div class="layout-auto-fr align-items-center">
    <div
      class="text-28 bold flex"
      [ngClass]="'product-match-' + getColorByMatchingScore(product.matchingScore)">
      {{ product.matchingScore | percent: "1.0" }}
      <span class="text-10">match</span>
    </div>
    <div class="grid gap-1 justify-self-end">
      <div class="text-10 bold uppercase justify-self-end">Product end date</div>
      <div class="text-18">
        {{ product.mortgageProductSteps[0].endDate | date: "dd MMMM yyyy" }}
      </div>
    </div>
  </div>

  <div class="layout-fr-fr">
    <div class="grid gap-1">
      <div class="text-10 bold uppercase">Product</div>
      <div class="text-18 bold">
        {{ product.mortgageProductSteps[0].interestRate | percent: "1.2-2" }}
        {{ product.mortgageProductSteps[0].termMonths | term }}
        {{
          ENUM_MortgagePartStepTypeMap.get(
            product.mortgageProductSteps[0].initialMortgagePartStepType
          )
        }}
      </div>
    </div>
    <div class="grid gap-1 justify-self-end">
      <div class="text-10 bold uppercase justify-self-end">MAX LTV</div>
      <div class="text-18">
        {{ product.maxLtvRatio | percent: "1.2-2" }}
      </div>
    </div>
  </div>
</div>
