import { Injectable, inject } from '@angular/core';
import { HTTP_Pagination_Response } from '@api-new/common';
import { HTTP_CP_MatchMortgageProducts_Response_MortgageProduct, MortgageApiService } from '@api-new/mortgageservice';
import { ComponentStore } from '@ngrx/component-store';
import { MortgagePartFormValues } from '../mortgage-borrowed-amount-and-term-form/utils/form-utils';
import { MortgageFormProductMatchingInfo } from './models/mortgage-form-product-matching-info.model';

interface MortgageProductMatchFormState {
  productMatchingInfo: MortgageFormProductMatchingInfo | null;
  mortgageParts: MortgagePartFormValues[];
  pagination: HTTP_Pagination_Response;
}

const initialMortgageProductMatchFormState: MortgageProductMatchFormState = {
  productMatchingInfo: null,
  mortgageParts: [],
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
  },
};

@Injectable()
export class MortgageProductMatchFormStore extends ComponentStore<MortgageProductMatchFormState> {
  protected readonly mortgageApiService = inject(MortgageApiService);

  constructor() {
    super(initialMortgageProductMatchFormState);
  }

  // SELECTORS ---------------------------------------------------------------------------------------------------------
  readonly mortgageParts$ = this.select((state) => state.mortgageParts);

  // EFFECTS -----------------------------------------------------------------------------------------------------------

  // UPDATERS ----------------------------------------------------------------------------------------------------------
  readonly setMortgageParts = this.updater((state, mortgageParts: MortgagePartFormValues[]) => {
    const productsMap = new Map<number, HTTP_CP_MatchMortgageProducts_Response_MortgageProduct[]>();
    mortgageParts.forEach((_, index) => productsMap.set(index, []));
    return {
      ...state,
      mortgageParts,
      productsMap,
    };
  });
}
