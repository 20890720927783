import { AsyncPipe, DatePipe, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { IconComponent } from '@shared-lib/components/icon/icon.component';
import { SkeletonModule } from 'primeng/skeleton';
import { CarouselComponent } from '../../carousel/carousel.component';
import { MortgagePartFormGroup, MortgagePartFormValues } from '../mortgage-borrowed-amount-and-term-form/utils/form-utils';
import { ProductMatchItemListComponent } from './components/product-match-item-list/product-match-item-list.component';
import { MortgageFormProductMatchingInfo } from './models/mortgage-form-product-matching-info.model';
import { MortgageProductMatchFormStore } from './mortgage-product-match-form.store';
import { MortgageProductMatchFormGroup } from './utils/form-utils';

function transformFormArrayToPart(formArray: FormArray<MortgagePartFormGroup>): MortgagePartFormValues[] {
  return formArray.getRawValue().map((part) => ({
    borrowedAmount: part.borrowedAmount,
    term: part.term,
    startDate: part.startDate,
    repaymentType: part.repaymentType,
    initialRateType: part.initialRateType,
    initialRatePeriod: part.initialRatePeriod,
    initialInterestRate: part.initialInterestRate,
  }));
}

export const SKIPPED_PRODUCT_MATCH_PLACEHOLDER = 'SKIPPED_PRODUCT_MATCH_PLACEHOLDER';

@Component({
  selector: 'cui-mortgage-product-match-form',
  imports: [DatePipe, NgOptimizedImage, SkeletonModule, AsyncPipe, CarouselComponent, IconComponent, ProductMatchItemListComponent],
  templateUrl: './mortgage-product-match-form.component.html',
  styleUrl: './mortgage-product-match-form.component.scss',
  providers: [MortgageProductMatchFormStore],
})
export class MortgageProductMatchFormComponent {
  @Input({ required: true }) productMatchFormGroup!: MortgageProductMatchFormGroup;
  @Input({ required: true }) productMatchingInfo: MortgageFormProductMatchingInfo | null = null;
  @Input({ transform: transformFormArrayToPart, required: true }) set mortgageParts(mortgageParts: MortgagePartFormValues[]) {
    this.componentStore.setMortgageParts(mortgageParts);
    if (this.productMatchFormGroup.controls.partsMortgageProductIds.length !== mortgageParts.length) {
      this.productMatchFormGroup.controls.partsMortgageProductIds.clear();
      mortgageParts.forEach((_) =>
        this.productMatchFormGroup.controls.partsMortgageProductIds.push(
          new FormControl<string>(null!, { validators: [Validators.required], nonNullable: true }),
        ),
      );
    }
  }

  @Output() forceNextStep = new EventEmitter<void>();

  private readonly componentStore = inject(MortgageProductMatchFormStore);

  protected readonly mortgageParts$ = this.componentStore.mortgageParts$;

  protected currentPart = 0;

  setSelectedProduct(productId: string | undefined, nextPage: boolean): void {
    if (productId == null) return;
    this.productMatchFormGroup.controls.partsMortgageProductIds.at(this.currentPart).setValue(productId);
    if (nextPage) {
      this.changeCurrentPart(this.currentPart + 1);
    }
    if (this.productMatchFormGroup.controls.partsMortgageProductIds.valid && productId === SKIPPED_PRODUCT_MATCH_PLACEHOLDER) {
      this.emitForceNextStep();
    }
  }

  changeCurrentPart(part: number): void {
    this.currentPart = part;
  }

  emitForceNextStep(): void {
    this.forceNextStep.emit();
  }
}
