import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'cui-carousel-indicators',
  imports: [CommonModule],
  templateUrl: './carousel-indicators.component.html',
  styleUrl: './carousel-indicators.component.scss',
})
export class CarouselIndicatorsComponent {
  @ViewChild('carouselIndicators') carouselIndicators: ElementRef | undefined;

  @Input({ required: true }) set setItemsLength(numberOfIndicators: number) {
    this.items = [...Array(numberOfIndicators).keys()];
  }

  @Input({ required: true }) set setCurrentPage(val: number) {
    this.currentPage = val;
    this.moveCarousel();
  }

  @Input() variant: 'light' | 'dark' = 'light';

  currentPage = 0;
  items: number[] = [];

  private moveCarousel(): void {
    if (this.currentPage < 7 || !this.carouselIndicators?.nativeElement) return;
    this.carouselIndicators.nativeElement.style.transform = `translateX(-${this.currentPage - 7}rem)`;
  }
}
