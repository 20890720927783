import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from '@api-new/authservice';
import { HTTP_CP_UpdateClientUser_Request, UserApiService } from '@api-new/userservice';
import { environment } from '@environments/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { paths } from '@platform/paths';
import { ToastService, ToastSeverity } from '@shared-lib/services/toast.service';
import { DASHLY_COMPANY_ID } from '@shared/contants';
import { ErrorModel } from '@shared/models/app-state.model';
import { askForTermsAndConditions } from '@shared/store/auth/auth.action';
import { selectRedirectUrl } from '@shared/store/auth/auth.selectors';
import {
  deleteClientUser,
  deleteClientUserFailure,
  deleteClientUserSuccess,
  dismissClientUserLandlordPropertyBanner,
  dismissClientUserLandlordPropertyBannerFailure,
  dismissClientUserLandlordPropertyBannerSuccess,
  getClientUser,
  getClientUserSuccess,
  provideClientUserDeleteCode,
  provideClientUserDeleteCodeFailure,
  provideClientUserDeleteCodeSuccess,
  updateClientUser,
  updateClientUserFailure,
  updateClientUserSilently,
  updateClientUserSuccess,
} from '@shared/store/client-user/client-user.actions';
import { getCompanyByIDAction } from '@shared/store/company/company.actions';
import { getSmartSearchesFailure } from '@shared/store/smart-searches/smart-searches.actions';
import moment from 'moment/moment';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

const transformUserModelDatesToStrings = (user: HTTP_CP_UpdateClientUser_Request): HTTP_CP_UpdateClientUser_Request => ({
  ...user,
  dateOfBirth: user.dateOfBirth ? moment(user.dateOfBirth).format() : null,
});

@Injectable()
export class ClientUserEffects {
  private readonly userApiService = inject(UserApiService);
  private readonly authApiService = inject(AuthApiService);
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly toastService = inject(ToastService);
  private readonly router = inject(Router);

  // handleLoadInitialPlatformData = createEffect(() => this.actions$.pipe(ofType(loadInitialPlatformData), map(getClientUser)));

  getUser = createEffect(() =>
    this.actions$.pipe(
      ofType(getClientUser),
      switchMap(() => this.userApiService.HTTP_CP_GetClientUser()),
      map((clientUser) => getClientUserSuccess({ clientUser })),
      mergeMap(({ clientUser }) => [
        getClientUserSuccess({ clientUser }),
        getCompanyByIDAction({ id: String(clientUser.companyId || DASHLY_COMPANY_ID) }),
      ]),
      catchError((error) => of(getSmartSearchesFailure({ error }))),
    ),
  );

  getUserSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(getClientUserSuccess),
      withLatestFrom(this.store.select(selectRedirectUrl)),
      map(([{ clientUser }, redirectUrl]) => {
        if (!clientUser.hasAcceptedTermsAndConditions && !this.router.url.startsWith(`/${paths.AGGREGATOR_LANDING_PAGE}/`)) {
          return askForTermsAndConditions();
        } else {
          if (redirectUrl) {
            void this.router.navigateByUrl(redirectUrl);
            return { type: 'NO_ACTION' };
          }
          if (!location.pathname.includes(paths.PLATFORM) && !this.router.url.startsWith(`/${paths.AGGREGATOR_LANDING_PAGE}/`)) {
            void this.router.navigate([paths.PLATFORM, paths.platform.DASHBOARD]);
          }
          return { type: 'NO_ACTION' };
        }
      }),
    ),
  );

  updateUser = createEffect(() =>
    this.actions$.pipe(
      ofType(updateClientUser),
      switchMap(({ clientUser }) => {
        return this.userApiService.HTTP_CP_UpdateClientUser(transformUserModelDatesToStrings(clientUser)).pipe(
          map((updatedUser) => {
            this.toastService.showToast(ToastSeverity.success, 'User was successfully updated');
            return updateClientUserSuccess({ clientUser: updatedUser });
          }),
          catchError((error) => {
            if (!environment.production) {
              console.error(error);
            }
            this.toastService.showToast(ToastSeverity.error, 'User failed to be updated');
            return of(updateClientUserFailure(error));
          }),
        );
      }),
    ),
  );

  silentUpdateUser = createEffect(() =>
    this.actions$.pipe(
      ofType(updateClientUserSilently),
      switchMap(({ clientUser }) => {
        return this.userApiService
          .HTTP_CP_UpdateClientUser(transformUserModelDatesToStrings(clientUser))
          .pipe(map((clientUser) => getClientUserSuccess({ clientUser })));
      }),
    ),
  );

  provideDeleteUserCode = createEffect(() =>
    this.actions$.pipe(
      ofType(provideClientUserDeleteCode),
      switchMap(() =>
        this.authApiService.HTTP_CP_ProvideClientUserDeleteCode().pipe(
          map(() => {
            this.toastService.showToast(ToastSeverity.success, 'Deletion e-mail has been sent to your e-mail address');
            return provideClientUserDeleteCodeSuccess();
          }),
          catchError((error) => {
            this.toastService.showToast(ToastSeverity.error, 'Unexpected error occurred while sending the deletion e-mail.');
            return of(provideClientUserDeleteCodeFailure(error));
          }),
        ),
      ),
    ),
  );

  deleteUserWithCode: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteClientUser),
      switchMap(({ code }) =>
        this.authApiService.HTTP_CP_DeleteClientUser_WithCode({ code }).pipe(
          map(() => deleteClientUserSuccess()),
          catchError((error) => of(deleteClientUserFailure(error))),
        ),
      ),
    ),
  );

  dismissLandlordPropertyBanner = createEffect(() =>
    this.actions$.pipe(
      ofType(dismissClientUserLandlordPropertyBanner),
      switchMap(() =>
        this.userApiService.HTTP_CP_UpdateClientUser_DismissPropertyBanner().pipe(
          map((response) => {
            return dismissClientUserLandlordPropertyBannerSuccess({ clientUser: response });
          }),
          catchError((error: ErrorModel) => {
            this.toastService.showToast(ToastSeverity.error, 'The banner failed to be updated');
            return of(dismissClientUserLandlordPropertyBannerFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
