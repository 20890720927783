import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ChildActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { App } from '@capacitor/app';
import { PushNotifications } from '@capacitor/push-notifications';
import { environment } from '@environments/environment';
import { init as initFullStory } from '@fullstory/browser';
import { paths } from '@platform/paths';
import { GlobalColorService } from '@shared/services/global-color.service';
import { NavigationService } from '@shared/services/navigation.service';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, mergeMap, startWith, take } from 'rxjs/operators';

@Component({
  selector: 'cp-root',
  templateUrl: './app.component.html',
  // eslint-disable-next-line @angular-eslint/prefer-standalone
  standalone: false,
})
export class AppComponent implements OnInit {
  private readonly navigationService = inject(NavigationService);
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly titleService = inject(Title);
  private readonly globalColorService = inject(GlobalColorService);
  private readonly destroyRef = inject(DestroyRef);

  readonly appLoading$: Observable<boolean> = combineLatest([
    this.router.events.pipe(
      filter((event) => event instanceof ChildActivationEnd),
      take(1),
      map(() => false),
      startWith(true),
    ),
  ]).pipe(map(([appLoading]) => appLoading));

  private navigationStartUrl = '';

  constructor() {
    this.initFullStory();
    this.globalColorService.initCompanyColorListener();

    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((data: { title: string }) => {
        this.titleService.setTitle(`${data.title ? `${data.title} | ` : ''}Mortgage Monitor`);
      });
  }

  ngOnInit(): void {
    App.addListener('backButton', () => {
      this.navigationService.back();
    });

    // Reset Sign Up CSS styles
    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.navigationStartUrl = location.pathname;
      }
      if (
        event instanceof NavigationEnd &&
        !event.url.startsWith(`/${paths.SIGN_UP}`) &&
        this.navigationStartUrl.startsWith(`/${paths.SIGN_UP}`)
      ) {
        window.location.reload();
      }
    });

    if (environment.isMobileApp) {
      // this.registerNotifications();
      // this.addListeners();
    }
  }

  registerNotifications = async (): Promise<void> => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
  };

  addListeners = async (): Promise<void> => {
    await PushNotifications.addListener('registration', (token) => {
      console.info('Registration token: ', token.value);
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', (notification) => {
      // ...
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      const data = notification.notification.data;
      if (data) {
        this.router.navigate([paths.PLATFORM, paths.platform.MAB_ARTICLES]);
      }
    });
  };

  private initFullStory(): void {
    initFullStory({
      orgId: '9ZEQS',
      devMode: !environment.production,
    });
  }
}
