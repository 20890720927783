<div class="carousel-layout">
  <div class="carousel-wrapper">
    <div
      class="carousel-items-container"
      (swipeleft)="swipeLeft()"
      (swiperight)="swipeRight()"
      #carouselItemsContainer>
      <ng-content #items />
    </div>

    @if (itemsLength > 1) {
      @if (currentPage > 0 && !disableNavigation) {
        <div class="nav-button left-button">
          <button
            pButton
            (click)="swipeRight()"
            class="p-button p-button-primary centered small p-2"
            [class]="arrowButtonStyleClass">
            <shared-icon icon="arrow-left" width="1rem" height="1rem" />
          </button>
        </div>
      }
      @if (itemsLength - 1 > currentPage && !disableNavigation) {
        <div class="nav-button right-button">
          <button
            pButton
            (click)="swipeLeft()"
            class="p-button p-button-primary centered small p-2"
            [class]="arrowButtonStyleClass">
            <shared-icon icon="arrow-right" width="1rem" height="1rem" />
          </button>
        </div>
      }
    }
  </div>
  @if (itemsLength > 1 && !hideIndicators) {
    <div class="mt-3">
      <cui-carousel-indicators
        [variant]="variant"
        [setItemsLength]="itemsLength"
        [setCurrentPage]="currentPage" />
    </div>
  }
</div>
