<div class="grid gap-3">
  @if (isLoadingProducts$ | async) {
    <p-skeleton
      *ngRepeat="3"
      class="overflow-hidden border-radius-3"
      width="100%"
      height="7.375rem" />
  } @else {
    @if (products$ | async; as products) {
      <div class="text-14 text-center">
        We've found
        @if (products.length > 1) {
          multiple {{ (mortgagePartInfo$ | async).productMatchingInfo.lenderName }} products. Pick
          the one that best matches yours. We sorted them by <b>matching&nbsp;score</b>, so the top
          one is the closest match.
        } @else {
          a {{ (mortgagePartInfo$ | async).productMatchingInfo.lenderName }} product that matches
          yours.
        }
        Pay special attention to the product end date, as it's key to accurate monitoring.
      </div>
      @for (product of products; track product) {
        <cui-product-match-item
          [selected]="product.id === selectedProductId()"
          [product]="product"
          (click)="selectedProductId.set(product.id)" />
      }
      @if (isLoadingMoreProducts$ | async) {
        <p-skeleton
          *ngRepeat="3"
          class="overflow-hidden border-radius-3"
          width="100%"
          height="7.375rem" />
      }
      @if ((isLastPage$ | async) === false) {
        <div class="text-14 bold-800 text-center pointer" (click)="loadMoreProducts()">
          Load more...
        </div>
      }
      <div
        class="text-18 bold-800 mb-2 pointer"
        (click)="selectedProductId.set(SKIPPED_PRODUCT_MATCH_PLACEHOLDER)">
        I'm not sure, none of the above
      </div>
    } @else {
      @if (mortgagePartInfo$ | async; as mortgagePartInfo) {
        <shared-labeled-value-inline-container
          contentStyleClass="bg-gray-5 border-radius-3 overflow-hidden p-3 text-14">
          It appears that {{ mortgagePartInfo.productMatchingInfo.lenderName }} didn’t offer any
          {{ mortgagePartInfo.mortgagePart.initialRatePeriod | monthsToText }}
          {{ ENUM_MortgagePartStepTypeMap.get(mortgagePartInfo.mortgagePart.initialRateType) }}
          products in {{ mortgagePartInfo.mortgagePart.startDate | date: "MMMM yyyy" }}. Please
          review the initial period and start date you’ve entered to ensure accuracy. We’re here to
          ensure every detail is spot-on so we can offer you the finest options tailored to your
          needs. Appreciate your help!
        </shared-labeled-value-inline-container>
        <div
          class="text-18 bold-800 mt-2 pointer"
          (click)="selectedProductId.set(SKIPPED_PRODUCT_MATCH_PLACEHOLDER)">
          Skip this
          {{ mortgagePartInfo.productMatchingInfo.singlePartRate ? "step" : "part" }} and refine
          later
        </div>
      }
    }
  }
</div>
