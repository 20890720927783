import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { IconComponent } from '../icon/icon.component';

export enum LabeledValueInlineContainerStyle {
  DEFAULT = '',
  SUCCESS = 'success',
  DANGER = 'danger',
}

@Component({
  selector: 'shared-labeled-value-inline-container',
  imports: [TooltipModule, NgClass, IconComponent],
  templateUrl: './labeled-value-inline-container.component.html',
  styleUrls: ['./labeled-value-inline-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledValueInlineContainerComponent {
  @Input() hasBorder = true;
  @Input() showOverflow = false;
  @Input() hideHeader = false;
  @Input() tooltip: string | undefined;
  @Input() headline: string | undefined;
  @Input() titleStyleClass: string | undefined;
  @Input() contentStyleClass: string | undefined;
  @Input() style: LabeledValueInlineContainerStyle = LabeledValueInlineContainerStyle.DEFAULT;
}
