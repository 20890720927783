import { SmartSearchPageEnum } from '@app/sign-up/enums/smart-search-page.enum';
import { UserPageEnum } from '@app/sign-up/enums/user-page.enum';
import { FlowStepsModel } from '@app/sign-up/models/flow-step.model';
import { paths } from '@platform/paths';

export const BUY_TO_LET_FLOW = {
  SmartSearchLiveResults: 0,
  UserCreate: 1,
} as const;

export const BUY_TO_LET_FLOW_STEPS: FlowStepsModel<typeof BUY_TO_LET_FLOW> = {
  [BUY_TO_LET_FLOW.SmartSearchLiveResults]: {
    step: BUY_TO_LET_FLOW.SmartSearchLiveResults,
    name: 'SmartSearchLiveResults',
    page: {
      route: paths.signUp.SmartSearch,
      name: SmartSearchPageEnum.LiveResults,
    },
  },
  [BUY_TO_LET_FLOW.UserCreate]: {
    step: BUY_TO_LET_FLOW.UserCreate,
    name: 'UserCreate',
    page: {
      route: paths.signUp.User,
      name: UserPageEnum.UserCreate,
    },
  },
};
