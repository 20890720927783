import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthsToText',
  standalone: true,
})
export class MonthsToTextPipe implements PipeTransform {
  transform(months: number, format: 'short' | 'long' = 'short'): string {
    if (months !== 0 && !months) {
      return null;
    }
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    if (remainingMonths === 0) {
      return `${years} year${years > 1 ? 's' : ''}`;
    }
    if (format === 'long') {
      return years
        ? `${years} year${years > 1 ? 's' : ''} and ${remainingMonths} month${remainingMonths > 1 ? 's' : ''}`
        : `${remainingMonths} month${remainingMonths > 1 ? 's' : ''}`;
    } else {
      return years ? `${years}y ${remainingMonths}m` : `${remainingMonths}m`;
    }
  }
}
