import { Injectable, inject } from '@angular/core';
import { ENUM_MortgageType, ENUM_PropertyInvestmentType, HTTP_Pagination_Request, HTTP_Pagination_Response } from '@api-new/common';
import {
  HTTP_CP_MatchMortgageProducts_Request,
  HTTP_CP_MatchMortgageProducts_Response,
  HTTP_CP_MatchMortgageProducts_Response_MortgageProduct,
  MortgageApiService,
} from '@api-new/mortgageservice';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { dateToISOStringWithoutTimezone } from '@shared-lib/utils';
import { Observable, filter } from 'rxjs';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MortgagePartFormValues } from '../../../mortgage-borrowed-amount-and-term-form/utils/form-utils';
import { MortgageFormProductMatchingInfo } from '../../models/mortgage-form-product-matching-info.model';

interface ProductMatchItemListState {
  productMatchingInfo: MortgageFormProductMatchingInfo | null;
  mortgagePart: MortgagePartFormValues | null;
  products: HTTP_CP_MatchMortgageProducts_Response_MortgageProduct[];
  pagination: HTTP_Pagination_Response;
  loadingActions: {
    isLoadingProducts: boolean;
    isLoadingMoreProducts: boolean;
  };
}

const initialProductMatchItemListState: ProductMatchItemListState = {
  productMatchingInfo: null,
  mortgagePart: null,
  products: [],
  pagination: {
    pageNumber: 0,
    pageSize: 5,
    totalCount: 0,
    totalPages: 0,
  },
  loadingActions: {
    isLoadingProducts: false,
    isLoadingMoreProducts: false,
  },
};

@Injectable()
export class ProductMatchItemListStore extends ComponentStore<ProductMatchItemListState> {
  private readonly mortgageApiService = inject(MortgageApiService);

  constructor() {
    super(initialProductMatchItemListState);
  }

  // SELECTORS ---------------------------------------------------------------------------------------------------------
  readonly isLoadingProducts$ = this.select((state) => state.loadingActions.isLoadingProducts);
  readonly isLoadingMoreProducts$ = this.select((state) => state.loadingActions.isLoadingMoreProducts);
  readonly products$ = this.select((state) => state.products);
  readonly mortgagePartInfo$ = this.select((state) => ({
    productMatchingInfo: state.productMatchingInfo,
    mortgagePart: state.mortgagePart,
  }));

  readonly isLastPage$ = this.select((state) => state.pagination.pageNumber >= state.pagination.totalPages);

  // EFFECTS -----------------------------------------------------------------------------------------------------------
  readonly getProductsForMatching = this.effect(
    (
      getProductsForMatching$: Observable<{
        productMatchingInfo: MortgageFormProductMatchingInfo;
        mortgagePart?: MortgagePartFormValues;
      }>,
    ) =>
      getProductsForMatching$.pipe(
        tap(({ productMatchingInfo, mortgagePart }) => {
          this.setIsLoadingProducts(true);
          this.setMortgagePartInfo({ productMatchingInfo, mortgagePart });
        }),
        switchMap(({ mortgagePart, productMatchingInfo }) => {
          return this.mortgageApiService
            .HTTP_CP_MatchMortgageProducts(
              mapToProductMatchRequest(productMatchingInfo, mortgagePart ?? null, { pageNumber: 1, pageSize: 5 }),
            )
            .pipe(
              tapResponse(
                (response: HTTP_CP_MatchMortgageProducts_Response) => {
                  this.setProducts(response);
                },
                () => this.setIsLoadingProducts(false),
              ),
            );
        }),
      ),
  );

  readonly loadMoreProducts = this.effect((loadMoreProducts$: Observable<void>) =>
    loadMoreProducts$.pipe(
      tap(() => {
        this.incrementPagination();
        this.setIsLoadingMoreProducts(true);
      }),
      withLatestFrom(this.select((state) => state)),
      filter(([_, state]) => state.productMatchingInfo != null),
      switchMap(([_, { productMatchingInfo, mortgagePart, pagination }]) =>
        this.mortgageApiService
          .HTTP_CP_MatchMortgageProducts(mapToProductMatchRequest(productMatchingInfo!, mortgagePart, pagination))
          .pipe(
            tapResponse(
              (response: HTTP_CP_MatchMortgageProducts_Response) => {
                this.setProducts({
                  mortgageProducts: [...this.get().products, ...response.mortgageProducts],
                  pagination: response.pagination,
                });
                this.setIsLoadingMoreProducts(false);
              },
              () => this.setIsLoadingMoreProducts(false),
            ),
          ),
      ),
    ),
  );

  // UPDATERS ----------------------------------------------------------------------------------------------------------
  readonly setIsLoadingProducts = this.updater(
    (state, isLoadingProducts: boolean): ProductMatchItemListState => ({
      ...state,
      loadingActions: {
        ...state.loadingActions,
        isLoadingProducts,
      },
    }),
  );

  readonly setIsLoadingMoreProducts = this.updater(
    (state, isLoadingMoreProducts: boolean): ProductMatchItemListState => ({
      ...state,
      loadingActions: {
        ...state.loadingActions,
        isLoadingMoreProducts,
      },
    }),
  );

  readonly setProducts = this.updater<HTTP_CP_MatchMortgageProducts_Response>(
    (state, response): ProductMatchItemListState => ({
      ...state,
      products: response.mortgageProducts,
      pagination: response.pagination ?? initialProductMatchItemListState.pagination,
      loadingActions: {
        isLoadingProducts: false,
        isLoadingMoreProducts: false,
      },
    }),
  );

  readonly setMortgagePartInfo = this.updater<{
    productMatchingInfo: MortgageFormProductMatchingInfo | undefined;
    mortgagePart: MortgagePartFormValues | undefined;
  }>(
    (state, mortgagePartInfo): ProductMatchItemListState => ({
      ...state,
      productMatchingInfo: mortgagePartInfo.productMatchingInfo ?? null,
      mortgagePart: mortgagePartInfo.mortgagePart ?? null,
    }),
  );

  readonly setPagination = this.updater((state, pagination: HTTP_Pagination_Response) => ({
    ...state,
    pagination,
  }));

  private readonly incrementPagination = this.updater((state) => ({
    ...state,
    pagination: {
      ...state.pagination,
      pageNumber: state.pagination.pageNumber + 1,
    },
  }));
}

// HELPERS -----------------------------------------------------------------------------------------------------------
const mapToProductMatchRequest = (
  productMatchingInfo: MortgageFormProductMatchingInfo,
  mortgagePart: MortgagePartFormValues | null,
  pagination: HTTP_Pagination_Request,
): HTTP_CP_MatchMortgageProducts_Request => {
  const currentInterestRate = productMatchingInfo?.singlePartRate
    ? productMatchingInfo.singlePartRate / 100
    : (mortgagePart?.initialInterestRate ?? undefined);
  return {
    mortgageLenderId: productMatchingInfo.lenderId,
    propertyCurrentValueAsOwnershipShare: productMatchingInfo.propertyCurrentValueAsOwnershipShare,
    mortgageType:
      productMatchingInfo.propertyInvestmentType === ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_RESIDENTIAL
        ? ENUM_MortgageType.MORTGAGE_TYPE_RESIDENTIAL
        : ENUM_MortgageType.MORTGAGE_TYPE_BUY_TO_LET,
    mortgageTotalInitialAmount: productMatchingInfo.mortgageTotalInitialAmount,
    mortgagePart: mortgagePart
      ? {
          startDate: dateToISOStringWithoutTimezone(mortgagePart.startDate) ?? undefined,
          repaymentType: mortgagePart.repaymentType,
          initialAmount: mortgagePart.borrowedAmount,
          termMonths: mortgagePart.term,
          initialMortgagePartStepType: mortgagePart.initialRateType ?? undefined,
          initialMortgagePartStepTermMonths: mortgagePart.initialRatePeriod,
          ...(productMatchingInfo.singlePartMonthlyPayment
            ? { currentMonthlyPayment: productMatchingInfo.singlePartMonthlyPayment }
            : { currentInterestRate: currentInterestRate }),
        }
      : undefined,
    pagination,
  };
};
