import { CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Injectable, NgModule, inject, provideAppInitializer } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ActivatedRoute, NavigationEnd, PreloadingStrategy, Route, Router, RouterModule } from '@angular/router';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Action, ActionReducer, MetaReducer, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ErrorService } from '@platform/OLD_error/error.service';
import * as Sentry from '@sentry/browser';
import { Event } from '@sentry/types';
import { SharedToastComponent } from '@shared-lib/components/toast/shared-toast.component';
import { Environment } from '@shared-lib/environment/environment';
import { ApplicationHttpClient, applicationHttpClientCreator } from '@shared-lib/services/application-http.service';
import { PreloadCheckpoints } from '@shared/enums/preload-checkpoints.enum';
import { AuthGuard } from '@shared/guards/auth.guard';
import { DashlyErrorInterceptor } from '@shared/interceptors/dashly-error-interceptor';
import { DashlyHttpInterceptor } from '@shared/interceptors/dashly-http-interceptor';
import { AppState } from '@shared/models/app-state.model';
import { Fullstory } from '@shared/models/fullstory.model';
import { AuthService } from '@shared/services/auth.service';
import { ErrorHandlerModule } from '@shared/services/error-handler/error-handler.module';
import { NavigationService } from '@shared/services/navigation.service';
import { effects, reducers } from '@shared/store';
import { logoutAndRedirect, resetStore } from '@shared/store/auth/auth.action';
import { getClientUser } from '@shared/store/client-user/client-user.actions';
import { selectClientUser } from '@shared/store/client-user/client-user.selectors';
import { CustomSerializer } from '@shared/store/route-serializer';
import { MessageService } from 'primeng/api';
import { providePrimeNG } from 'primeng/config';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AppComponent } from './app.component';
import { routes } from './app.routes';

declare global {
  interface Window {
    FS: Fullstory;
    dataLayer: unknown[];
  }
}
Sentry.init({
  dsn: environment.sentryDSN,
  denyUrls: [/localhost/],
  attachStacktrace: true,
  beforeSend: (event, hint): Event => {
    if (window.FS) {
      const error = hint.originalException;
      if (error != null) {
        if (typeof error === 'string') {
          window.FS.event('Application Error', { message: error, sentryEventId: hint.event_id });
        } else if (error instanceof Error) {
          window.FS.event('Application Error', {
            name: error.name,
            message: error.message,
            stack: error.stack,
            sentryEventId: hint.event_id,
          });
        }
      }
    }
    return event;
  },
});

@Injectable({ providedIn: 'root' })
export class PreloadSelectedModulesList implements PreloadingStrategy {
  checkpoints: Set<PreloadCheckpoints> = new Set<PreloadCheckpoints>();

  constructor(router: Router, activatedRoute: ActivatedRoute) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => activatedRoute),
        map((route) => {
          while (route.firstChild) {
            // gets the deepest child
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
      )
      .subscribe((route) => {
        if (route.snapshot.data['preloadCheckpoint'] != null) {
          this.checkpoints.add(route.snapshot.data['preloadCheckpoint']);
        }
      });
  }

  preload(route: Route, load: () => Observable<unknown>): Observable<unknown> | null {
    if (route.data && (route.data['preload'] || this.checkpoints.has(route.data['preloadAfterCheckpoint']))) {
      return load();
    } else {
      return of(null);
    }
  }
}

export function resetState(reducer: ActionReducer<AppState | Partial<AppState>>): ActionReducer<AppState | Partial<AppState>> {
  return (state: AppState, action: Action): AppState | Partial<AppState> => {
    switch (action.type) {
      case logoutAndRedirect.type:
      case resetStore.type:
        return reducer({}, action);
      default:
        return reducer(state, action);
    }
  };
}

export const metaReducers: MetaReducer[] = [resetState];

@Injectable()
export class DefaultHammerConfig extends HammerGestureConfig {
  override overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([...effects]),
    StoreDevtoolsModule.instrument({
      maxAge: 75,
    }),
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadSelectedModulesList,
      scrollPositionRestoration: 'top',
    }),
    HammerModule,
    ErrorHandlerModule,
    GoogleMapsModule,
    SharedToastComponent,
  ],
  providers: [
    MessageService,
    CurrencyPipe,
    ErrorService,
    NavigationService,
    PreloadSelectedModulesList,
    GoogleMapsModule,
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    provideAppInitializer(() => {
      const store = inject(Store);
      const authService = inject(AuthService);

      if (authService.token()) {
        store.dispatch(getClientUser());
        store.select(selectClientUser).pipe(
          filter(Boolean),
          take(1),
          map(() => true),
        );
      }
    }),
    {
      provide: Environment,
      useValue: environment,
    },
    {
      provide: ApplicationHttpClient,
      useFactory: applicationHttpClientCreator,
      deps: [HttpClient, Environment],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DashlyErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DashlyHttpInterceptor,
      multi: true,
    },
    AuthGuard,
    // PrimeNG
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        options: {
          cssLayer: {
            name: 'primeng',
          },
        },
      },
    }),
    // Angular
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HAMMER_GESTURE_CONFIG, useClass: DefaultHammerConfig },
  ],
})
export class AppModule {}
