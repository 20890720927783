import { HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod } from '@api-new/authservice';
import { BUY_TO_LET_FLOW } from '@app/sign-up/constants/buy-to-let-flow';
import { ENTRY_FLOW } from '@app/sign-up/constants/entry-flow';
import { FIRST_TIME_BUYER_FLOW } from '@app/sign-up/constants/first-time-buyer-flow';
import { MORTGAGE_MONITOR_FLOW } from '@app/sign-up/constants/mortgage-monitor-flow';
import { MOVING_HOME_FLOW } from '@app/sign-up/constants/moving-home-flow';
import { REMORTGAGE_NOW_FLOW } from '@app/sign-up/constants/remotgage-now-flow';
import { SignUpState } from '@app/sign-up/models/sign-up-state.model';
import { EntryLandingEnum } from '@app/sign-up/pages/entry/components/entry-landing/enums/entry-landing.enum';

export function getNextStep(appState: SignUpState): number {
  const currentStep = appState.flow.currentStep;

  // Entry - Landing
  if (currentStep === ENTRY_FLOW.Landing) {
    if (appState.flow.entity.landing === EntryLandingEnum.Remortgage) {
      return ENTRY_FLOW.MortgageSwitchUrgency;
    }
    if (appState.flow.entity.landing === EntryLandingEnum.TrackMortgage) {
      return MORTGAGE_MONITOR_FLOW.PropertyAddress;
    }
    if (appState.flow.entity.landing === EntryLandingEnum.BuyProperty) {
      return ENTRY_FLOW.BuyerType;
    }
  }

  // Entry - Mortgage Switch Urgency
  if (currentStep === ENTRY_FLOW.MortgageSwitchUrgency) {
    if (appState.flow.entity.creationMethod === HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_MORTGAGE_MONITOR) {
      return MORTGAGE_MONITOR_FLOW.PropertyAddress;
    }
    if (appState.flow.entity.creationMethod === HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_REMORTGAGE_NOW) {
      return REMORTGAGE_NOW_FLOW.PropertyAddress;
    }
  }

  // Remortgage Now
  if (appState.flow.entity.creationMethod === HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_REMORTGAGE_NOW) {
    return handleRemortgageNowFlow(appState);
  }

  // First Time Buyer
  if (appState.flow.entity.creationMethod === HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_FIRST_TIME_BUYER) {
    return handleFirstTimeBuyerFlow(appState);
  }

  // Moving Home
  if (appState.flow.entity.creationMethod === HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_MOVING_HOME) {
    return handleMovingHomeFlow(appState);
  }

  // Buy To Let
  if (appState.flow.entity.creationMethod === HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_BUY_TO_LET) {
    return handleBuyToLetFlow(appState);
  }

  return currentStep + 1;
}

function handleRemortgageNowFlow(appState: SignUpState): number {
  const currentStep = appState.flow.currentStep;

  if (currentStep === REMORTGAGE_NOW_FLOW.SmartSearchLiveResults && appState.smartSearch.entity?.mortgageProductId != null) {
    return REMORTGAGE_NOW_FLOW.UserCreate;
  }

  return currentStep + 1;
}

function handleFirstTimeBuyerFlow(appState: SignUpState): number {
  const currentStep = appState.flow.currentStep;

  if (currentStep === ENTRY_FLOW.BuyerType) {
    return FIRST_TIME_BUYER_FLOW.SmartSearchBuyingProcessStage;
  }

  return currentStep + 1;
}

function handleMovingHomeFlow(appState: SignUpState): number {
  const currentStep = appState.flow.currentStep;

  if (currentStep === ENTRY_FLOW.BuyerType) {
    return MOVING_HOME_FLOW.SmartSearchBuyingProcessStage;
  }
  if (currentStep === MOVING_HOME_FLOW.SmartSearchLiveResults && appState.smartSearch.entity?.mortgageProductId != null) {
    return MOVING_HOME_FLOW.UserCreate;
  }

  return currentStep + 1;
}

function handleBuyToLetFlow(appState: SignUpState): number {
  const currentStep = appState.flow.currentStep;

  if (currentStep === ENTRY_FLOW.BuyerType) {
    return BUY_TO_LET_FLOW.SmartSearchLiveResults;
  }

  return currentStep + 1;
}
