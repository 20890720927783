@if (!hideHeader) {
  <div class="layout-fr-auto align-items-center">
    <div class="text-10 bold-700 px-3 py-2 uppercase" [ngClass]="titleStyleClass">
      {{ headline }}
      @if (tooltip) {
        <shared-icon
          [pTooltip]="tooltip"
          class="fill-dark ml-2"
          icon="info"
          height="0.75rem"
          width="0.75rem" />
      }
    </div>
    <div>
      <ng-content select="[headerContent]" />
    </div>
  </div>
}

<div class="border-radius-3 overflow-hidden" [ngClass]="contentStyleClass">
  <ng-content />
</div>
