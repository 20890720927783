@if (mortgageParts$ | async; as mortgageParts) {
  <h1 class="text-28 bold-800 text-center pb-4">
    Select your current deal
    @if (mortgageParts.length > 1) {
      for each part
    }
  </h1>
  @if (productMatchingInfo) {
    <div class="flex-column gap-3 overflow-hidden">
      @if (mortgageParts.length > 1) {
        <div class="flex gap-2 scroll-horizontal">
          @for (mortgagePart of mortgageParts; track mortgagePart; let i = $index) {
            <div
              class="flex gap-2 grid-2 bg-gray-5 bold border-radius-2 px-3 py-2 text-12 uppercase align-items-center part-item"
              [class.product-selected]="
                productMatchFormGroup.controls.partsMortgageProductIds.at(i).value
              "
              [class.focus]="currentPart === i"
              (click)="changeCurrentPart(i)">
              <span class="text-12 uppercase bold">Part&nbsp;{{ i + 1 }}</span>
              @if (productMatchFormGroup.controls.partsMortgageProductIds.at(i).value) {
                @if (
                  productMatchFormGroup.controls.partsMortgageProductIds.at(i).value.trim().length >
                  0
                ) {
                  <shared-icon
                    class="fill-green"
                    icon="check-square"
                    height="1.25rem"
                    width="1.25rem" />
                } @else {
                  <shared-icon
                    class="fill-gray-3"
                    icon="minus-square"
                    height="1.25rem"
                    width="1.25rem" />
                }
              }
            </div>
          }
        </div>
      }
      <div class="grid gap-3">
        <div class="grid gap-3 mt-3">
          <cui-carousel
            (pageChange)="changeCurrentPart($event)"
            [itemsLength]="mortgageParts.length"
            [setCurrentPage]="currentPart">
            @for (
              mortgagePart of mortgageParts;
              track mortgagePart;
              let lastPart = $last;
              let i = $index
            ) {
              <div class="layout-auto-fr part gap-3">
                <div class="logo-wrapper">
                  @if (productMatchingInfo) {
                    <img [ngSrc]="productMatchingInfo.lenderLogo" fill />
                  }
                </div>
                <div class="grid gap-1 justify-self-end">
                  <div class="text-10 bold uppercase">Product start date</div>
                  <div class="text-18 justify-self-end">
                    {{ mortgagePart.startDate | date: "MMMM yyyy" }}
                  </div>
                </div>
                @if (currentPart === i) {
                  <cui-product-match-item-list
                    class="span-column-2"
                    [mortgagePartInfo]="{
                      productMatchingInfo,
                      mortgagePart: mortgagePart,
                    }"
                    [selectedProductId]="
                      productMatchFormGroup.value.partsMortgageProductIds?.[currentPart]
                    "
                    (selectedProductIdChange)="setSelectedProduct($event, !lastPart)" />
                }
              </div>
            }
          </cui-carousel>
        </div>
      </div>
    </div>
  }
}
